export enum CerbosRole {
	GCM = 'Global Commodity Manager',
	SCP = 'Supply Chain Professional',
	Engineer = 'Engineer', // not in matrix (empty column)
	MCET = 'MCET',
	BusinessAdmin = 'Business Admin',
	SystemAdmin = 'System Admin',
	BU = 'BU'
}

export const RoleNamesMap = {
	[CerbosRole.GCM]: 'Commodity Manager',
	[CerbosRole.SCP]: 'Supply Chain Professional',
	[CerbosRole.Engineer]: 'Engineer',
	[CerbosRole.MCET]: 'MCET',
	[CerbosRole.BusinessAdmin]: 'Business Admin',
	[CerbosRole.SystemAdmin]: 'System Admin',
	[CerbosRole.BU]: 'BU'
} as Record<string, string>;

export enum CerbosEffect {
	Allow = 'EFFECT_ALLOW',
	Deny = 'EFFECT_DENY'
}

export enum CerbosResource {
	Quote = 'Quote',
	AdminPanel = 'AdminPanel'
}

export enum QuoteActions {
	// QUOTE ACTIONS
	Create = `${CerbosResource.Quote}:Create`,
	View = `${CerbosResource.Quote}:View`,
	DeleteWhenScaRun = `${CerbosResource.Quote}:Delete When No SCA wasn't Run`,
	DeleteWhenNoSca = `${CerbosResource.Quote}:Delete When No SCA was Run`,
	Close = `${CerbosResource.Quote}:Close`,
	Edit = `${CerbosResource.Quote}:Edit`,
	Search = `${CerbosResource.Quote}:Search`,
	Duplicate = `${CerbosResource.Quote}:Duplicate`,
	AssignRegion = `${CerbosResource.Quote}:Assign Region`,
	AssignQuantity = `${CerbosResource.Quote}:Assign Quantity`,

	// INVINTATIONS
	InviteOwners = `${CerbosResource.Quote}:Invite Owners`,
	InviteContributors = `${CerbosResource.Quote}:Invite Contributors`,

	// PART ACTIONS
	AddPart = `${CerbosResource.Quote}:Add Part`,
	DeletePart = `${CerbosResource.Quote}:Delete Part`,
	ViewPart = `${CerbosResource.Quote}:View Part`,
	ConfigurePart = `${CerbosResource.Quote}:Configure Part`,

	SaveAndRunSca = `${CerbosResource.Quote}:Save & Run SCA`,
	ReRunSca = `${CerbosResource.Quote}:Re-Run SCA`,
	RequestAprioriEstimate = `${CerbosResource.Quote}:Request aPriori Estimate`,

	// DIGITAL FACTORIES ESTIMATES
	VerificationActions = `${CerbosResource.Quote}:Verifiction actions for Estimates`,

	AddManualEstimate = `${CerbosResource.Quote}:Add manual Estimate`,
	EditManualEstimate = `${CerbosResource.Quote}:Edit manual Estimate`,

	VerifyManualEstimate = `${CerbosResource.Quote}:Verify manual Estimate`,
	UnverifyManualEstimate = `${CerbosResource.Quote}:Unverify manual Estimate`,

	VerifyAprioriEstimate = `${CerbosResource.Quote}:Verify aPriori Estimate`,
	UnverifyAprioriEstimate = `${CerbosResource.Quote}:Unverify aPriori Estimate`,

	// SIMILAR PARTS
	ViewSimilarParts = `${CerbosResource.Quote}:View Similar Parts`,
	ViewSuggestedSuppliers = `${CerbosResource.Quote}:Show Suggested Suppliers`
}

export enum AdminPanelActions {
	Access = `${CerbosResource.AdminPanel}:Access Admin Panel`
}

export const AllRoles = [
	CerbosRole.GCM,
	CerbosRole.MCET,
	CerbosRole.SCP,
	CerbosRole.Engineer,
	CerbosRole.SystemAdmin,
	CerbosRole.BusinessAdmin,
	CerbosRole.BU
] as const;

export const UserRolesAbbrMap = {
	[CerbosRole.GCM]: 'GCM',
	[CerbosRole.MCET]: 'MCET',
	[CerbosRole.SCP]: 'SCP',
	[CerbosRole.Engineer]: 'ENG',
	[CerbosRole.SystemAdmin]: 'SA',
	[CerbosRole.BusinessAdmin]: 'BA',
	[CerbosRole.BU]: 'BU'
};

export const ActionToResourceMap = {
	[CerbosResource.AdminPanel]: [AdminPanelActions.Access]
} as Record<string, string[]>;

export function findResourceByAction(action: string): CerbosResource | undefined {
	const resource = Object.keys(ActionToResourceMap).find(resource => ActionToResourceMap[resource].includes(action));
	return resource as CerbosResource;
}
